// 외부모듈
import axios, { AxiosError } from "axios"
import React, { useEffect, useState } from "react"
import * as CryptoJs from "crypto-js"
import * as queryString from "query-string"
import { css } from "@emotion/core"

// 내부모듈
import EmailSignUpLayout from "../../components/emailSignUp/emailSignUpLayout"
import {
  Complete,
  Processing,
  Error,
  WrongPath,
} from "../../components/emailSignUp/resultUI"
import { secretKey } from "../../../keys/key.json"
import { API } from "../../constants/api"
import { IS_MOBILE } from "../../constants/env"

enum Page {
  processing,
  complete,
  error,
  wrongPath,
}

function Result({ location }: any) {
  const { p, e } = queryString.parse(location.search)
  const [index, setIndex] = useState(Page.processing)
  const [errorMessage, setErrorMessage] = useState("")

  const pages = [
    <Processing />,
    <Complete />,
    <Error message={errorMessage} />,
    <WrongPath />,
  ]

  useEffect(() => {
    if (!p || !e) {
      setIndex(Page.wrongPath)
    }

    const dp = decodeURIComponent(p as string)
    const de = decodeURIComponent(e as string)

    const phoneNum = CryptoJs.AES.decrypt(dp, secretKey).toString(
      CryptoJs.enc.Utf8
    )

    const email = CryptoJs.AES.decrypt(de, secretKey).toString(
      CryptoJs.enc.Utf8
    )

    axios
      .post(API.authMembers, { phoneNum, email })
      .then(res => {
        if (res.status === 400) setErrorMessage("Bad Request")
        if (res.data.result === true) {
          setIndex(Page.complete)
        } else {
          setErrorMessage(res.data.message)
        }
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          setErrorMessage(`status:${err.code} message: ${err.message}`)
        } else {
          setErrorMessage(err.message)
        }
      })
  }, [])

  useEffect(() => {
    if (errorMessage !== "") {
      setIndex(Page.error)
    }
  }, [errorMessage])

  return IS_MOBILE ? (
    <div css={mobile__container}>{pages[index]}</div>
  ) : (
    <EmailSignUpLayout>{pages[index]}</EmailSignUpLayout>
  )
}

const mobile__container = css`
  height: 100vh;
  padding: 0px;
`

export default Result
